import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { AuthLayoutComponent } from './shared/layout/app-layouts/auth-layout.component';

const routes: Routes = [


  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "auth",
        pathMatch: "full"
      },
      {
        path: "auth",
        loadChildren: "./features/auth/auth.module#AuthModule",
        data: { pageTitle: "Auth" }
      }
    ]
  },
  {
    path: 'main',
    loadChildren: './features/main/main.module#MainModule',
    data: { pageTitle: "Home" }
  },
  { path: '**', redirectTo: 'miscellaneous/error404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
