import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldFilterPipe } from './field-filter.pipe';
import { MomentPipe } from './moment.pipe';
import { SafePipe } from './safe.pipe';
import { CamelToTextPipe } from './camel-to-text.pipe';
import { FieldSortPipe } from './field-sort.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FieldFilterPipe, MomentPipe, SafePipe, CamelToTextPipe, FieldSortPipe],
  exports: [FieldFilterPipe, MomentPipe, SafePipe, CamelToTextPipe, FieldSortPipe]
})
export class PipesModule { }
