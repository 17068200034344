import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";


import { CollapseMenuComponent } from "./collapse-menu/collapse-menu.component";
import { FullScreenComponent } from "./full-screen/full-screen.component";

import { HeaderComponent } from "./header.component";

import { UtilsModule } from "@app/shared/utils/utils.module";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { I18nModule } from "@app/shared/i18n/i18n.module";
import { UserModule } from "@app/shared/user/user.module";
import { BsDropdownModule, PopoverModule } from "ngx-bootstrap";
import { RouterModule } from "@angular/router";
import { UserProfileComponent } from "./profile";


@NgModule({
  imports: [
    CommonModule,

    FormsModule,

    // VoiceControlModule,

    BsDropdownModule,

    UtilsModule, PipesModule, I18nModule, UserModule, PopoverModule, RouterModule
  ],
  declarations: [
    FullScreenComponent,
    CollapseMenuComponent,
    HeaderComponent,
    UserProfileComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
