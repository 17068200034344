import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, SPINNER, NgxUiLoaderConfig, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule } from 'ngx-ui-loader'
import { DatePipe, TitleCasePipe } from '@angular/common';
import { MonacoEditorModule } from 'ngx-monaco-editor';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  blur: 0.5,
  bgsColor: '#OOACC1',
  bgsOpacity: 0.5,
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 100,
  bgsType: SPINNER.chasingDots,
  fgsColor: '#283583',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 55,
  fgsType: SPINNER.ballSpinClockwiseFadeRotating,
  pbColor: '#283583',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 2,
  text: '',
  textColor: '#283583',
  textPosition: POSITION.centerCenter,
  overlayColor: 'rgba(40, 40, 40, 0.2)',
  masterLoaderId: 'loader'
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule
    , BrowserAnimationsModule
    , AppRoutingModule
    , SharedModule
    , CoreModule
    , NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
    , NgxUiLoaderRouterModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId })
    , NgxUiLoaderHttpModule.forRoot({ showForeground: true, loaderId: ngxUiLoaderConfig.masterLoaderId })
    , MonacoEditorModule.forRoot()


  ],
  providers: [DatePipe, TitleCasePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
