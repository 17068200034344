import { Injectable } from '@angular/core';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';
import { ApiService } from './api.service';
import { environment } from '@env/environment';
import { StorageService } from './storage.service';
import { FormGroup } from '@angular/forms';

@Injectable()
export class AuthService {
  public user$ = new BehaviorSubject(null);
  public isAdmin$ = new BehaviorSubject(null);
  public isConnectedWithRegTekUser$ = new BehaviorSubject(null);
  public accessLevel$ = new BehaviorSubject(null);

  isLoggedIn: boolean = false;
  userClaims: any;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private httpClient: HttpClient
    , private authToken: AuthTokenService
    , private apiService: ApiService
    , private storage: StorageService
  ) { }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          if (user) {
            this.user = user;
            this.userClaims = user.claims;
            this.user.admin = false;
            this.isConnectedWithRegTekUser = user.isConnectedWithRegTekUser;
            this.accessLevel = user.accessLevel;

            if (this.user.roles.some(x => x === "PortalAdministrator")) {
              this.user.admin = true;
            }

            resolve(user);
          }
          resolve(null);
        },
        error => {
          resolve(null);
        }
      )
    })
  }

  set user(value) {

    this.user$.next(value);
  }
  get user() {
    return this.user$.value;
  }

  set isAdmin(value) {
    this.isAdmin$.next(value);
  }
  get isAdmin() {
    return this.isAdmin$.value;
  }

  set isConnectedWithRegTekUser(value) {
    this.isConnectedWithRegTekUser$.next(value);
  }
  get isConnectedWithRegTekUser() {
    return this.isConnectedWithRegTekUser$.value;
  }

  set accessLevel(value) {
    this.accessLevel$.next(value);
  }
  get accessLevel() {
    return this.accessLevel$.value;
  }

  login(username: string, password: string, externalToken = null, otp = null): any {
    var grant_type = "password";
    var scope = "offline_access";

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let body = new URLSearchParams();
    body.set('grant_type', grant_type);
    body.set('username', username);
    body.set('password', password);
    body.set('scope', scope);
    body.set('externalToken', externalToken);
    body.set('otp', otp);

    return this.httpClient.post(environment.LOGINURL, body.toString(), httpOptions)
      .pipe(
        map((data: any) => {
          this.isLoggedIn = true;
          this.authToken.token = data.access_token;
          this.loadUser();
          return true;
        },
          catchError(this.handleError))
      );
  }

  loadUser(): void {
    this.apiService.getUser().subscribe((data) => {
      this.user = data;
      this.userClaims = data.claims;
      this.user.admin = false;
      if (this.user.roles.some(x => x === "PortalAdministrator")) {
        this.user.admin = true;
      }
      this.storage.set("USER", this.user).then(_ => {
        this.IsAdmin();
        this.IsConnectedWithRegTekUser();
        this.AccessLevel();

      });
    })
  }

  public IsAdmin() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          this.isAdmin = user.admin;
          resolve(this.isAdmin);
        },
        error => {
          reject("Error in Helper.IsAdmin");
        }
      )
    });
  }

  public IsConnectedWithRegTekUser() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          this.isConnectedWithRegTekUser = user.isConnectedWithRegTekUser;
          resolve(this.isConnectedWithRegTekUser);
        },
        error => {
          reject("Error in Helper.IsConnectedWithRegTekUser");
        }
      )
    });
  }

  public AccessLevel() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          this.accessLevel = user.accessLevel;
          resolve(this.accessLevel);
        },
        error => {
          reject("Error in Helper.AccessLevel");
        }
      )
    });
  }

  public GetUsername() {
    return new Promise((resolve, reject) => {
      this.storage.get("USER").then(
        user => {
          resolve(user.username);
        },
        error => {
          reject("Error in Helper.GetUsername");
        }
      )
    });
  }

  logout(): void {
    let _self = this;
    this.storage.remove("USER").then(_ => {
      //Also clear session storage
      this.storage.clearSessionStorage().then(_ => {
        _self.storage.remove
        _self.authToken.token = null;
        _self.isLoggedIn = false;
      });
    }
    );
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.error.error_description
      ? error.error.error_description
      : error.status
        ? `${error.status} - ${error.statusText}`
        : 'Server error';
    console.error(errMsg); // log to console instead
    return throwError(errMsg);
    //return Observable.throw(errMsg);
  }

  public checkUserPermission(permissionType: string, permissionToCheck: string) {
    var isFound = this.userClaims.some(x => x.type == permissionType && x[permissionToCheck] == true);
    return isFound != true ? false : true;
  }
}

export function AuthFactory(service: AuthService): Function {
  return () => service.load();
}